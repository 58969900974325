import { useState, useEffect, useRef } from 'react';
import { useInterval } from './useInterval';
import { UnObserveType, useInView } from './useInView';

export const useIntervalInView = (ref: any, fn: any, ms: number = 5000, option?: Partial<IntersectionObserverInit>): () => void => {
    const stopRef = useRef<any>();
    const unobserveRef = useRef<any>();
    const [start, setStart] = useState(false);

    const intervalFn = () => fn();

    const { startInterval, stopInterval } = useInterval(intervalFn, ms);


    const intersectFn = (entry: IntersectionObserverEntry, unObserve: UnObserveType) => {
        if(entry.isIntersecting) {
            setStart(true);
        } else {
            setStart(false);
        }
        unobserveRef.current = unObserve;
    }

    

    useInView(ref, intersectFn, option);


    useEffect(() => {
        if(start) {
            stopRef.current = startInterval(intervalFn);
        }
        return () => stopRef.current ? stopRef.current() : stopInterval();
    }, [start])

    return unobserveRef.current;
};