import { useEffect, useState } from 'react';
// import DatePicker from "react-datepicker";

// import "react-datepicker/dist/react-datepicker.css";
// import { convertTimestampToDateTime } from '../../../../utils';
// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

import { IInput } from '../../Field';



interface Props extends IInput {
}
export const DateTimeInput: React.FC<Props> = ({init, id, onChange, name}) => {
    const [value, setValue] = useState(init || new Date());

    const handleDateChange = (date: Date) => {
        onChange({name: name, value: date});
        setValue(date);
    }
    useEffect(() => {
        onChange({name: name, value: value})
    }, [])
    return (
        <div>
            NOT Implelemtn
        </div>
            // <DatePicker
            //     selected={value}
            //     id={id}
            //     name={name}
            //     onChange={handleDateChange}
            //     showTimeSelect
            //     dateFormat="MM/dd/yyyy h:mm aa"
            //     fixedHeight
            //     className='date-picker'
            //     />
    );
}

export const DateInput: React.FC<Props> = ({init, id, onChange, name}) => {
    //new Date(init.toDate())
    // const [value, setValue] = useState(init ? convertTimestampToDateTime(init) : new Date());

    const handleDateChange = (date: Date) => {
        onChange({name: name, value: date});
        // setValue(date);
    }
    useEffect(() => {
        // onChange({name: name, value: value})
    }, [])
    
    return (
        <div>
            NOT IMplemented
        </div>
            // <DatePicker
            //     id={id}
            //     selected={value}
            //     name={name}
            //     onChange={handleDateChange}
            //     dateFormat="MM/dd/yyyy"
            //     fixedHeight
            //     className='date-picker'
            //     />
    );
}