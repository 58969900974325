import { useRef } from 'react';
import './Banner.scss';
// import { banner_pic, logo } from '../../assets';
import banner_pic from "../../assets/images/team_pic.jpeg";
import logo from "../../assets/images/logo.png";
import { useSmartScroll } from '../../custom-hooks';


export const Banner = () => {
    const bannerRef = useRef<any>();

    const handler = (evt: Event) => {
        const banner: HTMLElement = bannerRef.current;
        // const header = banner.querySelector('header') as HTMLElement;
        const overLay = banner.querySelector('#b-overlay') as HTMLElement;
        const img = banner.querySelector('img') as HTMLElement;
        
        // parallaxEffect(banner);
        const rect = banner.getBoundingClientRect();

        const offset = (rect.top * -1);
        const height = rect.height;


        banner.style.backgroundPositionY = `${offset * 0.7}px`;
        

        const winHeight = (window.innerHeight / 2);
        const scrollOffset = window.pageYOffset;


        const logoScale = (1.2 - (scrollOffset / winHeight));
        logoScale >= 0 ? img.style.transform = `scale(${logoScale})` : img.style.transform = `scale(0)`;


        const opacityOffset = offset / (height / 2);
        const opty = 1 - (opacityOffset);
        opty >= 0 ? overLay!.style.opacity = `${opty}` : overLay!.style.opacity = `${0}`;
    }

    useSmartScroll(bannerRef, handler);
    
    return (
        <article ref={bannerRef} className='banner' style={{backgroundImage: `url(${banner_pic})`}} >
            {/* <Header /> */}
            <div className='content' id="banner" ></div>
            <div id="b-overlay">
                <div className="title">
                    <img src={logo as any} />
                    <h1>Flying Mambas</h1>
                    <p>We Lead The Rest!</p>
                </div>
            </div>
        </article>
    );
}
