import { useState, useEffect, useRef } from 'react';




type IntervalFxnType = (end: () => void) => void;

type IntervalCreatorType = (Fn: IntervalFxnType, ms?: number) => {
  stopInterval: () => void;
  startInterval: (fn: IntervalFxnType) => () => any;
};

export const useInterval: IntervalCreatorType = (Fn: IntervalFxnType, ms = 1000 / 60) => {
  const unsub = useRef<any>();
  const [end, setEnd] = useState(false);

  const stopInterval = () => {
    setEnd(true);
  }


  const startInterval = (fxn: IntervalFxnType) => {
    const interval = {id: 0};
    let prevTime = performance.now();// performance.now() gives me date.now
    const looper = (currentTime: number) => {
        // if caller provides timer like in the case of set interval
        if(ms) {
          // calculate time for frame to be repainted from the prevTime
          // if repaint time interval equals or is more than (usertime)ms
          // then we can run the user function - Fn
          const repaintTimeInterval = currentTime - prevTime;
          if(repaintTimeInterval >= ms) {
            fxn(stopInterval);
            // after function has run, reset the prevTime to currenttime
            prevTime = currentTime;
          }
        } else {
          fxn(stopInterval); // custom func
        }
        interval.id = window.requestAnimationFrame(looper);
    }

    interval.id = window.requestAnimationFrame(looper);
    unsub.current = () => window.cancelAnimationFrame(interval.id);
    return unsub.current;
  }

  useEffect(() => {
    unsub.current = startInterval(Fn);
    return () => {
      unsub.current();
    }
  }, [ms]);

  useEffect(() => {
    if(end) unsub.current();
  }, [end]);

  return { startInterval, stopInterval }
};