import React from 'react';
import './ContactContent.scss'
import { ContactForm } from './form';

export const ContactContent = () => {
    return (
        <article className='contacts'>
            <h1>Get Intouch!</h1>
            <div className="content">
                <div className="info">
                    <strong>Call Us</strong>: {('9098889898' as any).phone()}
                </div>
                <div className="message">
                    <ContactForm />
                </div>
            </div>
        </article>
    );
}
