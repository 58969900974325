export const setExtensions = () => {
    
    const type = (String.prototype as any);

    type.isEmpty = function () {
        return this.length === 0;
    };

    type.normalize = function() {
        return this.split(' ').join('').toLowerCase();
    }

    type.lowerCase = function() {
        return this.toLowerCase();
    }


    type.capitalizeFirstLetter = function() {
        if (this.isEmpty()) return '';
        return `${this[0].toUpperCase()}${this.slice(1).toLowerCase()}`
    }

    type.splitCamelCaseWordToCommaSeparatedString = function() {
        return this
            .split(/(?=[A-Z])/)
            .map((p: any) => {
            return p[0].toUpperCase() + p.slice(1);
            })
            .join(' ');
    }

    type.phone = function() {
        if(this.isEmpty()) return '';
        if(this.length === 3 || this.length < 4) return this;
        let phone = this;
        phone = phone.replace(/\D/g, '');
        const match = phone.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);
        if (match) {
            phone = `(${match[1]}${match[2] ? '' : ''}) ${match[2]}${match[3] ? '-' : ''}${match[3]}`;
        }
        
        return phone
    }

    // String to numbers
    type.int = function() {
        return parseInt(this, 10) // +this;
    }
    type.float = function() {
        return parseFloat(this); // +this
    }

    type.number = function() {
        return +this;
    }

    // Number type
    const numType = (Number as any)

    numType.string = function(): string {
        return this.toString();
    }
}