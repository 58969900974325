 
    export function castReturnValue<T>(res: any, data: any): T {
        const nRes: T = res;
        (nRes as any).data = data;
        return nRes;
    } 
    
    
    interface IPosition<T> {
        beforeAnEle: T;
        firstChildOfAnEle: T;
        lastChildOfAnEle: T;
        afterAnEle: T;
    }
    export const insertHTMLPositions: IPosition<any> = {
        beforeAnEle: 'beforebegin',
        firstChildOfAnEle: 'afterbegin',
        lastChildOfAnEle: 'beforeend',
        afterAnEle: 'afterend'
    }
    