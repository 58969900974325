import './App.scss';
import { Banner, VideoContent, BgImageSlider, ContactContent, Footer } from './components';
import { setExtensions } from './utils/extensions';

import ozi from "./assets/images/ozi.jpeg";
import team_pic from "./assets/images/team_pic.jpeg";
import { sliders } from './app/sliders';


function App() {
  setExtensions();

  // const bgs = sliders();

  const bgs = [
    {url: team_pic, title: 'We play like our lives depend on it!'},
    {url: ozi, title: 'The Ozi charm'}
  ];

  return (
    <div className="app">
      <Banner />
      <VideoContent />
      <BgImageSlider bgs={bgs} showNav={true} showTitle={true} />
      <ContactContent />
      <Footer />
    </div>
  );
}

export default App;
