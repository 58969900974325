import { useRef } from "react";

export const useManualScroll = (func: (evt: Event, unsubscribe: () => void) => void, ele?: HTMLElement) => {
    const targetRef = useRef<any>();
  
  
    const unsubscribe = () => {
      targetRef.current.removeEventListener('scroll', func);
    }
  
    const handler = (e: Event) => func(e, unsubscribe);

    const addListener = (target: any = window) => {
      targetRef.current = target;
      target.addEventListener('scroll', handler);
    }
  
    const removeListener = (target: any = window) => {
      target.removeEventListener('scroll', handler);
      targetRef.current = null;
    }
  
  
    return { addListener, removeListener };
  };