import './VideoContent.scss';
import bg_video from "../../assets/videos/prague.mp4";
import { Desc } from '../others';

export const VideoContent = () => {
 return (
    <article className='video-content'>
        <div className='content'>
            <Desc />
            <div className="img-wrapper">
            {/* autoplay="autoplay" loop="loop" muted defaultMuted playsinline  oncontextmenu="return false;"  preload="auto"  id="myVideo" */}
                {/* <video style={{width: '300px', height: '300px', objectFit: 'cover'}} autoPlay loop muted > */}
                <video style={{width: '300px', height: '300px', objectFit: 'cover'}}
                 autoPlay={true} loop={true} muted={true}  playsInline={true}  onContextMenu={() => false}  preload="auto"
                 >
                    <source src={bg_video} type="video/mp4" />
                </video>
                <div className='v-overlay'></div>
                <div style={{
                    fontSize: '0.8rem',
                    textAlign: 'right',
                    fontWeight: '700'
                }}>Prague, 2022</div>
            </div>
        </div>
    </article>
 );
}
