
    export function padTo2Digits(num: any) {
        return num.toString().padStart(2, '0');
      }
      
    
    
    //  Generate numbers
    export function generateRandomNumber() {
        var minm = 100000;
        var maxm = 999999;
        return Math.floor(Math
        .random() * (maxm - minm + 1)) + minm;
    }
    
    
    
    
    
    
    