import { useState } from "react";
import { IEmailForm, sendEmailAsync } from "../../../utils";
import { fieldTypes, Form, IField } from "../../others";


const fields: IField[] = [
    { name: "user_name", label: 'Name', type: fieldTypes.text, required: true},
    { name: "user_email", label: 'Email', type: fieldTypes.email, required: true},
    // { name: "subject", label: 'Subject', type: fieldTypes.text, required: true},
    { name: "subject", label: 'Subject', type: fieldTypes.text, required: true},
    { name: "message", label: 'Message', type: fieldTypes.textarea, required: true},
]


export const ContactForm = () => {
    const [emailSent, setSent] = useState({sent: false, msg: ''});
    const [init, setInit] = useState<any>(null);
    const formName = "contact";
   
    const handleSubmit = async (values: any) => {
        const fv: IEmailForm = values as IEmailForm;
        fv.payment_method = "General Contact";
        const res: any = await sendEmailAsync(fv);
        if(res.success) {
            setInit((state: any) => ({}));
            setSent({sent: true, msg: 'We will get back to you.'});
        } else {
            setSent({sent: false, msg: 'Please try again later!'});
        }
        
    }

    
    return (
        <Form formName={formName} onSubmit={handleSubmit} fields={fields} initObj={init} settings={{btnLabel: 'Send Message'}}>
            {emailSent.msg && (
                <div className="b4-form" style={{ color: emailSent.sent ? 'green' : 'red'}}>
                    <div>
                        {/* {emailSent.sent ? <CheckmarkIcon /> : <ErrorIcon />} */}
                    </div>
                    <div>
                        {emailSent.msg}
                    </div>
                </div>
            )}
        </Form>
    )
}