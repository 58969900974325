import React from 'react';
import { Arrow } from '../others';
import './Footer.scss'

export const Footer = () => {
 return (
    <footer>
                
        <div className="content">
            
            <div className='go-to-top'>
                <div onClick={() => window.scrollTo({top: 0, left: 0, behavior: 'smooth'})}>
                    <Arrow clicked={() => null} style={{color: 'black'}} />
                    <strong>TOP</strong>
                </div>
            </div>
        </div>
    </footer>
 );
}
