import { useState, useEffect } from 'react';
import { useInView } from '../useInView';
import { useListener } from './useListener';

export const useOutsideClick = (ref: any) => {
  const [isOutsideClick, setClick] = useState(false);
  
    const handleListener = (e: Event) => {
        if (!ref.current.contains(e.target)) {
            setClick(true);
        } else {
            setClick(false);
        }
    }

    const {addListener, removeListener} = useListener('mousedown', handleListener);

    const viewHandler = (entry: IntersectionObserverEntry) => {
        if(entry.isIntersecting) {
            addListener(window.document as any);
        } else {
            removeListener(window.document as any);
        }
    }

    useInView(ref, viewHandler)
    return { isOutsideClick }
};