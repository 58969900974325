import emailjs, { EmailJSResponseStatus } from "@emailjs/browser";


export interface IEmailForm {
    user_name: string;
    user_email: string;
    subject: string;
    message: string;
    payment_method?: string;
}

const sendEmail = async (funcAsync: any, values: any) => {
    try {
        const res: EmailJSResponseStatus =  await funcAsync(
            `${process.env.REACT_APP_TOURNAMENT_PAYMENT_EMAIL_SERVICE}`, 
            `${process.env.REACT_APP_NGO_EMAIL_TEMPLATE_SERVICE}`, 
            values,
            `${process.env.REACT_APP_EMAIL_SERVICE_PUBLIC_KEY}`);
            
        if(res.status < 400) {
            return {success: true, message: ''}
        } else {
            return {success: false, message: 'Email sending failure! Please try again later!'}
        }
    } catch (error: any) {
        return {success: false, message: error || 'Email sending failure! Please try again later!'}
    }
};

export const sendFormEmailAsync = async (form: HTMLFormElement) => { 
    return await sendEmail(emailjs.sendForm, form);
}

export const sendEmailAsync = async (values: IEmailForm) => {
    return await sendEmail(emailjs.send, values);
};