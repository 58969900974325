import { useRef } from 'react';
import { useInView } from '../useInView';
import { useManualScroll } from './useManualScroll';

export const useSmartScroll = (ref: React.MutableRefObject<HTMLElement | any>, handler: any, options?: IntersectionObserverInit) => {
    const initRef = useRef<boolean>(false);
    const { addListener, removeListener } = useManualScroll(handler);

    const whatEntry = (entry: IntersectionObserverEntry) => {
        if(entry.isIntersecting) {
            if(!initRef.current) {
                handler({target: ref.current});
            }
            addListener();
        } else {
            removeListener();
        }
    }

    useInView(ref, whatEntry, {threshold: 0.1, ...(options ? options : {})});
    
};