import React from 'react';
import './Arrow.scss'

export const Arrow = ({clicked, dir='top', style={}}: {clicked: () => void; dir?: string; style?: Object;}) => {
    const cn = `arrow ${dir}`;
    
    return (
        <span onClick={clicked} className={cn} style={style}>
            &#8963;
        </span>
    );
}
