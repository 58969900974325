// interface IString {

// import { Timestamp } from "firebase/firestore";
import { padTo2Digits } from "./numbers";


type Timestamp = any;

    
   
      
      function formatTime(milliseconds: number) {
        const seconds = Math.floor(milliseconds/1000);
        const s = Math.floor(seconds%60);
        const m = Math.floor((seconds%(60 * 60)) / 60);
        const h = Math.floor((seconds%(60 * 60 * 24))/(60*60));
        let formattedTime = h.toString().padStart(2, '0');
        formattedTime += ":";
        formattedTime += m.toString().padStart(2, '0');
        formattedTime += ":";
        formattedTime += s.toString().padStart(2, '0');
        return formattedTime;
      }
      

    export function formatDate(d: Date) {
        const date = convertToDate(d);
        return [
            padTo2Digits(date.getMonth() + 1),
          padTo2Digits(date.getDate()),
          date.getFullYear(),
        ].join('/');
    }
    export function convertTimestampToDateString(date: Timestamp | any): string {
        return formatDate(date);
    }
    export function convertTimestampToDateTimeString(date: Timestamp | any): string {
        return convertToDate(date).toTimeString();
    }
    function getDTString(date: Date): string {
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        var d = date;
        var day = days[d.getDay()];
        var hr = d.getHours();
        var min = d.getMinutes();
        if (min < 10) {
            (min as any) = "0" + min;
        }
        var ampm = "am";
        if( hr > 12 ) {
            hr -= 12;
            ampm = "pm";
        }
    
        return day + ", " + hr + ":" + min + ampm;
    }
    
    export function getDayTimeString(date: Date): string {
        return getDTString(date);
    }
    
    export function getDayTimeStringFromTimestamp(timestamp: Timestamp | any): string {
        return getDTString(convertToDate(timestamp));
    }
    function getDateMonthYrStr(x: Date): string {
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var d = x;
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        return date + " " + month + " " + year;
    }
    
    
    export function getDateMonthAndYrString(date: Date): string {
        return getDateMonthYrStr(date)
    }
    export function getDateMonthAndYrStringFromTimestamp(timestamp: Timestamp | any): string {
        return getDateMonthYrStr(convertToDate(timestamp))
    }
    
    
    function convertToDate(dateOrTimestamp: Timestamp | Date | any): Date {
        // console.log(dateOrTimestamp);
        if(dateOrTimestamp.seconds) {
            // this is for timestamps
            let seconds = dateOrTimestamp?.seconds * 1000;
            if(dateOrTimestamp.nanoseconds) {
                // adding nanoseconds to seconds
                const nanoseconds = (dateOrTimestamp?.nanoseconds / (1000*1000*1000));
                seconds = (seconds + nanoseconds);
            }
            // console.log(new Date(seconds).toDateString());
            return new Date(seconds);//.toDateString()
        } else {
            return new Date(dateOrTimestamp);
        }
    }
    export function convertTimestampToDateTime(date: Timestamp | any): Date {
        // console.log(date);
        // console.log(typeof date);
        // console.log(convertToDate(date));
        return convertToDate(date);
    }
    
    export function getAge(date: Timestamp | any) {
        var today = new Date();
        var birthDate = convertToDate(date);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
        {
            age--;
        }
        return age;
    }
    
    export function dateToTimeAgo(date: Date): string {
        const now = new Date(Date.now());
        const difftime = now.getTime() - date.getTime();
        const diffDate = new Date(difftime - 5.5 * 60 * 60 * 1000);
        const [sec, min, hr, day, month] = [
            diffDate.getSeconds(),
            diffDate.getMinutes(),
            diffDate.getHours(),
            diffDate.getDate() - 1,
            diffDate.getMonth(),
        ];
        const f = (property: any, end: any) =>{
            // console.log(property,end)
            return`${property} ${end}${property > 1 ? "s" : ""} ago`;
        }
        // console.log(diffDate.toLocaleString());
        return month >= 1
            ? f(month, "month")
            : day >= 1
            ? f(day, "day")
            : hr >= 1
            ? f(hr, "hr")
            : min >= 1
            ? f(min, "min")
            : day >= 1
            ? f(sec, "sec")
            : "";
    
    
        throw new Error("Date To time ago not implmented");
    }
    
    export function getTimeOfDay(name: string) {
    
        var myDate = new Date();
        var hrs = myDate.getHours();
        var mins = myDate.getMinutes(); // changed date to myDate
        var greet;
      
        //   morning |  5:30-11:59
        // afternoon | 12:00-17:59
        //   evening | 18:00-05:29
        if (hrs >= 5 && ((hrs == 5 && mins >= 30) || (hrs > 5 && hrs < 12)))
          greet = 'Good Morning';
        else if (hrs >= 12 && hrs < 18)
          greet = 'Good Afternoon';
        else if ((hrs >= 18 && hrs < 24) || hrs > 0)
          greet = 'Good Evening';
        else
          greet = 'Good day';
      
          return `${greet}, ${name}!`;
      }
    
    
    
    
    
    