export const useListener = (evtType: keyof WindowEventMap, fn: any) => {
    // const targetRef = useRef<any>(ele);

    const handler = (e: Event) => fn(e);

    const addListener = (target: HTMLElement) => {
      // targetRef.current = target;
      // const target = targetRef.current;
      target.addEventListener(evtType, handler);
    }
  
    const removeListener = (target: HTMLElement) => {
      // const target = targetRef.current;
      target.removeEventListener(evtType, handler);
      // targetRef.current = null;
    }
  
  
    return { addListener, removeListener };
}