import React, { useState } from 'react';
import { IObject, formatDate } from '../../../utils';
import { Field, IInputResponse } from './field';
import './Form.scss'
import { checkFormRequiredValidity, IForm, IFormSettings, ISegmentedField } from './FormHelpers';



const defaultSettings: IFormSettings = {showBtn: true, btnLabel: 'Submit', vertical: true, canSubmit: true, clr: 'primary'};

export const Form: React.FC<IForm> = ({
    formName, 
    fields,
    segmentFields,
    initObj,
    onSubmit = () => null, 
    children,
    settings: ss = {} as IFormSettings
}) =>{
    const [settings] = useState({...defaultSettings, ...ss});

    const [values, setValues] = useState<IObject>({});
    const [error, setError] = useState('');
    const [canSubmit, setCanSubmit] = useState(settings.canSubmit);
    // const {alertUser} = useAlert();
    

    const handle = (v: IInputResponse) => {
        setValues(x => ({...x, [v.name]: v.value}));
    }

    const handleSubmit = () => {
        setError('');
        const editableKeys = Object.keys(values);
        const allKeys = Object.keys(initObj || {});
        const isEdit = allKeys.length > 0;
        const valuesToValidate = isEdit 
        ? JSON.parse(JSON.stringify(Object.assign({}, initObj, values))) 
        : values;
        const results = segmentFields ? 
        checkSegValidity(valuesToValidate, segmentFields) : checkFormRequiredValidity(valuesToValidate, fields);
        if(results.length > 0) return setError(results[0]);
        
        if(isEdit && editableKeys.length > 0) {
            let isAccount = false;
            let shouldSubmit = false;
            // did anything changed
            for (let i = 0; i < editableKeys.length; i++) {
                const key = editableKeys[i];

                const newValue = values[key];
                const originalValue = (initObj as IObject)[key];

                const nD = formatDate(values[key]);
                const oD = formatDate((initObj as IObject)[key]);

                if(newValue instanceof Date) {
                    if(nD != oD) {
                        shouldSubmit = true;
                    }
                } else if(newValue != originalValue) {
                    if(values[key] instanceof Date) {
                        console.log(`${formatDate(values[key])} && ${formatDate((initObj as IObject)[key])} are not the same`);
                    }

                    if(["email", "password"].includes(key) && !isAccount) {
                        isAccount = true;
                        shouldSubmit = true;
                    } else {
                        shouldSubmit = true;
                    }
                }

            }

            if(shouldSubmit) {
                onSubmit(valuesToValidate, isAccount);
            } else {
                // alertUser("You didn't make any change that needs to be submitted!");
            }
            
        } else {
            onSubmit(valuesToValidate);
        }

    }
    
    const checkSegValidity = (vals:  {
        [x: string]: any;
    }, seg: ISegmentedField[]) => {
        let output: string[] = [];
        for (let i = 0; i < seg.length; i++) {
            const header = seg[i].header;
            const sFields = seg[i].fields;
            // if((header as any).lowerCase() === 'account') {
            //     const re = checkAccountValidity(values, sFields);
            //     output = [...output, ...re];
            // } else {
            //     const re = checkFormRequiredValidity(values, sFields);
            //     output = [...output, ...re];
            // }
            const re = checkFormRequiredValidity(vals, sFields);
            output = [...output, ...re];
        }
        return output;
    }


    return (
        <form onSubmit={(e: any) => {e.preventDefault()}} className={`form ${(formName as any).normalize()}-form ${settings.vertical ? 'vertical' : ''}`}>
            {/* <AwError error={error} /> */}
            {children}
            {segmentFields ? (
                <>
                    {segmentFields.map((section, id) => {
                        return (
                            <div key={id} className="segment">
                                <h2 className='section-header'>{section.header}</h2>
                                <div className="segment-body">
                                {section.fields.map((field, idx) => {
                                    return <Field initObj={initObj} formName={formName.normalize()} key={idx} field={field} changeHandler={handle}/>;
                                })}
                                </div>
                            </div>
                        )
                    })}
                </>
            ) : 
            (<>
            {fields.map((field, idx) => {
                return <Field initObj={initObj} formName={formName.toLowerCase()} key={idx} field={field} changeHandler={handle}/>;
             })}
            </>)
            }
            

            { settings.showBtn && (
                <div className="form-btn-div">
                    <button type="button" disabled={!canSubmit} className={`${settings.clr}`} onClick={handleSubmit}>{settings.btnLabel}</button>
                </div>
            ) }
        </form>
    )
}