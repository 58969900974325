import { useEffect, useRef } from 'react';
type TargetType = HTMLElement | Element;
export type UnObserveType = (ele: TargetType) => void;

export const useInView = (eleRef: any, func: (entry: IntersectionObserverEntry, unobserve: UnObserveType) => void, options?: IntersectionObserverInit) => {
  const ref = useRef<IntersectionObserver>();

  const disconnect:UnObserveType = (target: TargetType) => {
    if(ref.current) ref.current.unobserve(target);
  }

  useEffect(() => {
    const ele = eleRef.current;
    const watcher = new IntersectionObserver(([entry], observer) => {
        ref.current = observer;
        func(entry, () => disconnect(entry.target));
    }, options ? {...options} : options);

    if(ele) watcher.observe(ele);
    
    return () => {
        if(ele) {
            watcher.unobserve(ele);
        }
    };
  }, [options]);
};
