import { useRef } from 'react';
import { useInView } from '../../../custom-hooks';
import './Desc.scss';

export const Desc = () => {
    const ref = useRef<any>();
    
    const inViewFn = (entry: IntersectionObserverEntry) => {
        const show = 'show';
        const target = ref.current;
        const header = target.querySelector('h1');
        const para = target.querySelector('p');

        if(entry.isIntersecting) {
            header.classList.add(show);
            para.classList.add(show);
        } else {
            header.classList.remove(show);
            para.classList.remove(show);
        }
    }

    useInView(ref, inViewFn, {threshold: 1});

    return (
        <div ref={ref} className='des'>
            <h1>WW Language!</h1>
            <p>Speak soccer, Speak Prague!</p>
        </div>
    );
}
