
   
import React, { useRef, useState } from 'react';
import { useIntervalInView } from '../../custom-hooks';
import { Arrow } from '../others';
import './BgImageSlider.scss';

interface IBgImageSlider {
    bgs: IBgImage[];
    showNav?: boolean;
    showTitle?: boolean;
}

export const BgImageSlider: React.FC<IBgImageSlider> = ({
    bgs, 
    showNav = true, 
    showTitle = false}) => {
    const sliderRef = useRef<any>();
    const defaultTimer = 5000;
    const [slide, setSlide] = useState(0);
    const [timer, setTimer] = useState(defaultTimer);
    
    const handleSliding = (cur: number) => {
        setSlide(state => {
            const nIndex = state + cur;
            let idx = nIndex === bgs.length ? 0 : nIndex < 0 ? bgs.length - 1 : nIndex;
            return idx;
        });
    }
    const prev = () => {
        handleSliding(-1);
        setTimer(10000);
    }
    
    const next = () => {
        handleSliding(1);
        if(timer !== defaultTimer) setTimer(defaultTimer);
    }
    
    useIntervalInView(sliderRef, next, timer, {threshold: 0.5});
    
    
    return (
        <div ref={sliderRef} className='bg-image-slider'>
                <BgImage bg={bgs[slide]} showTitle={showTitle} />
                {showNav && <>
                {/* <div onClick={prev} className="left-arrow"> &#8963; </div>
                <div onClick={next} className="right-arrow"> &#8963; </div> */}

                <Arrow clicked={prev} dir="left-arrow"  />
                <Arrow clicked={next} dir="right-arrow"  />
                </>}
        </div>
    );
    }
    
    
    
    interface IBgImage {
        url: string;
        title: string;
    }

    const BgImage: React.FC<{bg: IBgImage; showTitle?: boolean;}> = ({bg, showTitle = false}) => {
        return (
            <div className='img-wrapper'>
                <div style={{backgroundImage: `url(${bg.url})`}} />
                {/* <div style={{backgroundImage: `${bg.url}`}} /> */}
                {showTitle && <h1> {bg.title} </h1>}
            </div>
        )
    }
    